<template>
  <div class="container">
    <div class="content">
      <div class="item item_2">
        <div class="title">核心指标</div>
        <div class="detail">
          <div
            class="itemDetail"
            v-for="(item, index) in entryData"
            :key="index"
          >
            <div class="label" :class="['icon_' + (index + 1)]">
              {{ item.label }}
            </div>
            <div class="desc">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="item item_5">
        <div class="title">经销商战力榜</div>
        <div class="detail">
          <ul class="rank_list">
            <li
              v-for="(item, index) in companyData"
              :class="'rank_' + (index + 1)"
              :key="item.id"
            >
              <span class="rank_no"
                ><b>{{ item.rank }}</b></span
              ><span class="rank_name">{{ item.name }}</span>
              <div class="rank_bg">
                <span
                  class="rank_pecent"
                  :style="'width:' + item.value + '%'"
                ></span>
              </div>
              <span class="rank_num">{{ item.value }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="item item_6">
        <div class="title">销售员战力榜</div>
        <div class="detail">
          <ul class="rank_list">
            <li
              v-for="(item, index) in sellerData"
              :class="'rank_' + (index + 1)"
              :key="item.id"
            >
              <span class="rank_no"
                ><b>{{ item.rank }}</b></span
              ><span class="rank_name">{{ item.name }}</span>
              <div class="rank_bg">
                <span
                  class="rank_pecent"
                  :style="'width:' + item.value + '%'"
                ></span>
              </div>
              <span class="rank_num">{{ item.value }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="item item_4">
        <table class="table" cellspacing="0" cellspadding="0">
          <tr>
            <th></th>
            <th>排行</th>
            <th>战斗力<br />指数</th>
            <th>战力上岗<br />得分</th>
            <th>跟进完成<br />得分</th>
            <th>成交逾期<br />扣分</th>
            <th>跟进质量<br />爆灯得分</th>
            <th>成交<br />得分</th>
            <th>战败<br />得分</th>
          </tr>
          <tr v-for="item in listData" :key="item.id">
            <th @click="jumpToArea(item)">{{ item.name }}</th>
            <td>{{ item.rank }}</td>
            <td :style="'color:' + getColor(item.value1)">{{ item.value1 }}</td>
            <td :style="'color:' + getColor(item.value2)">{{ item.value2 }}</td>
            <td :style="'color:' + getColor(item.value3)">{{ item.value3 }}</td>
            <td>{{ item.value4 }}</td>
            <td :style="'color:' + getColor(item.value5)">{{ item.value5 }}</td>
            <td :style="'color:' + getColor(item.value6)">{{ item.value6 }}</td>
            <td>{{ item.value7 }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { postData } from "@/api/index";
import {jump} from "@/utils/index"
export default {
  data() {
    return {
      entryData: [],
      companyData: [],
      sellerData: [],
      listData: [],
    };
  },
  created() {
    this.getDataBySearch();
  },
  methods: {
    async getDataBySearch() {
      const query = { is_mobile:1,...this.$route.query, ...this.$store.state.query };
      this.loading = true;
      const { data = {} } = await postData("/api/board_battle/region", query);
      const { list, core, company, seller } = data;
  
     
      this.entryData = [
        { label: "战斗力指数", value: core.core1 },
        { label: "成交率", value: core.core2 },
        { label: "战败率", value: core.core3 },
        { label: "爆灯率", value: core.core4 },
        { label: "逾期率", value: core.core5 },
      ];
      this.companyData = company.slice(0, 6);
      this.sellerData = seller.slice(0, 8);
      this.listData = list;
    },
    getColor(v) {
      if (v < 60) {
        return "red";
      }
      if (v >= 80) {
        return "green";
      }
      return "yellow";
    },
    jumpToArea(item) {
        let query={...this.$store.state.query,area_id:item.id};
      jump("/battle/area",this, query);
 

    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  @import '@/assets/index.scss';
  background: url("../../assets/bg_battle.png");
  background-size: cover;

  background-position: bottom center;
  .content {
    .item {
      display: flex;
      flex-direction: column;
      margin-top: 0.185185rem /* 10/54 */;
      &.item_1 {
        width: 9.666667rem /* 522/54 */;
        height: 7.907407rem /* 427/54 */;
        background: url("../../assets/battle_map.png");

        background-size: cover;
      }
      &.item_2 {
          width: 9.666667rem /* 522/54 */;
        height: 5.240741rem /* 283/54 */;
        background: url("../../assets/board.png");

        background-size: cover;
        .detail::after{
              content: '';
              display: block;
              width: 33.33%; 
        }
        .itemDetail{
          width: 33.33%;
         
        }
      }
      &.item_4 {
        width: 9.666667rem /* 522/54 */;
         border:1px solid #144cb9;
        box-shadow: 0px 0px 5px #144cb9 inset;
        color: #00d5e9;
        background-size: cover;
        font-size: 0.222222rem /* 12/54 */;
        padding: 0.185185rem /* 10/54 */;
        th {
          max-width: 0.851852rem /* 46/54 */;
        }
      }
      &.item_5 {
        width: 9.666667rem /* 522/54 */;
        height: 5.518519rem /* 298/54 */;

        background: url("../../assets/battle_shop.png");
        background-size: cover;
      }
      &.item_6 {
        width: 9.666667rem /* 522/54 */;
        height: 7.685185rem /* 415/54 */;

        background: url("../../assets/battle_user.png");
        background-size: cover;
      }
     
      .title {
        margin-left: 0.481481rem /* 26/54 */;
        color: #00d5e9;
        font-size: 0.407407rem /* 22/54 */;
        height: 0.962963rem /* 52/54 */;

        line-height: 0.962963rem /* 52/54 */;
      }
       
    }
  }
}
</style>